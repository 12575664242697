// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import MainRoute from './main';
import ScrollToTop from '../scrollToTop';

const createRoute = (props) => {
  const {store, history} = props
  const url = window.location.href.replace(/www./, '');
  //https://vifaakenya.org/#/nigeria/home
  const urlParsed = url.substr(url.indexOf('//') + 7 , url.indexOf('.') -13);

  return (
    <div className="main">
      <ScrollToTop>
        <Route exact path="/nigeria" render={() => <Redirect to="/nigeria/home" />} />
        <Route exact path="/ghana" render={() => <Redirect to="/ghana/home" />} />
        <Route exact path="/kenya" render={() => <Redirect to="/kenya/home" />} />
        <Route exact path="/senegal" render={() => <Redirect to="/senegal/home" />} />
        <Route exact path="/" render={() => <Redirect to={`/${urlParsed}/home`} />} />
        <Route path="/" render={() => <MainRoute store={store} history={history}/>} />
      </ScrollToTop>
    </div>
  )
}

export default createRoute
