import React from 'react'
import {injectReducer} from '../../../../../../store/store'
import {asyncComponent} from 'react-async-component'
import {VIFAA_COUNTRIES} from "../../../../utils/constants";
import {MODULE_NAMES, PAGES} from "../../../../modules/moduleConstants";

const createRoute = (props) => {
    const HomeComponent = asyncComponent({
        resolve: () => new Promise(resolve => {
            import('./modules/apparentConsumption').then(reducer => {
                injectReducer({
                    key: `${VIFAA_COUNTRIES.SENEGAL}_${PAGES.USE}_${MODULE_NAMES.APPARENT_CONSUMPTION}`,
                    reducer: reducer.default
                })
                import('./modules/averageConsumption').then(reducer => {
                    injectReducer({
                        key: `${VIFAA_COUNTRIES.SENEGAL}_${PAGES.USE}_${MODULE_NAMES.AVERAGE_CONSUMPTION}`,
                        reducer: reducer.default
                    })
                    import('./modules/domesticUreaConsumption').then(reducer => {
                        injectReducer({
                            key: `${VIFAA_COUNTRIES.SENEGAL}_${PAGES.USE}_${MODULE_NAMES.UREA_CONSUMPTION}`,
                            reducer: reducer.default
                        })
                        import('./layouts/mainLayout').then(view => {
                            resolve(view);
                        });
                    });
                });
            });
        })
    })
    return (
        <HomeComponent></HomeComponent>
    )
}

export default createRoute
